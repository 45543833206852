import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import { mq } from "../utils/presets"
import TopImage from "../components/TopImage"
import parse from "html-react-parser"

const Wrapper = styled.div`
  ${mq.tablet} {
    max-width: 1080px;
    margin: 0 auto;
    margin-bottom: 3rem;
  }
`

const Textwrapper = styled.div`
  padding: 1rem;
  ${mq.tablet} {
    margin-right: 1rem;
    h1 {
      text-align: left;
      border-bottom: none;
      margin-right: 1rem;
    }
  }
  ${mq.tablet} {
    margin-right: 0;
    padding-right: 0;
  }
`

const PageTemplate = ({ data }) => {
  const page = data.page
  const featuredImage = page?.featuredImage

  const seo = page?.seo
  const seoTitle = page?.seo?.opengraphTitle || page.title || ``
  const metaDesc = seo.metaDesc || ``
  const seoImage = page?.featuredImage?.node.localFile.childImageSharp.resize

  return (
    <Layout isFrontPage={page.isFrontPage}>
      <Seo
        seoTitle={seoTitle}
        description={metaDesc}
        image={seoImage}
        uri={page.uri}
        title={page.title}
      />{" "}
      {!!featuredImage && <TopImage featuredImage={featuredImage} />}
      <Wrapper>
        <Textwrapper>
          <h1>{page.title}</h1>
          {parse(page.content)}
        </Textwrapper>
      </Wrapper>
    </Layout>
  )
}
export default PageTemplate

export const query = graphql`
  query ($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      content
      isFrontPage
      uri
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        opengraphTitle
        opengraphDescription
        schema {
          articleType
          pageType
          raw
        }
      }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              resize(width: 1200, height: 627) {
                src
                tracedSVG
                width
                height
                aspectRatio
                originalName
              }
              gatsbyImageData(
                width: 2000
                placeholder: NONE
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`
